import { motion } from "framer-motion"
import { throttle } from "lodash"
import React, { useEffect } from "react"
import MenuContextProvider from "../../context/MenuContext"
import Header from "../Header"
import SocialMenu from "../Menu/social"

const Layout = ({ children, location }) => {
  // console.log(location)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let el = document.getElementsByTagName("main")[0]
      window.addEventListener(
        "scroll",
        throttle(function () {
          if (window.pageYOffset > 30) {
            el.classList.add("squeeze")
          } else {
            el.classList.remove("squeeze")
          }
        }, 1000)
      )
    }
  }, [])

  return (
    <MenuContextProvider>
      <main>
        <Header location={location} />
        {/* <header>
          <SiteMenu location={location} />
        </header> */}
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          {children}
        </motion.div>
      </main>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 menu">
              <SocialMenu location={location} />
            </div>
            <div className="col-md-12">
              © {new Date().getFullYear()} Kieferle & Partner. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </footer>
    </MenuContextProvider>
  )
}

export default Layout
